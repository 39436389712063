import { createApp } from 'vue'
import App from './App.vue'
import router from './routers'

import {
    FloatingBubble,
    NavBar,
    Button,
    Icon,
    Image as VanImage,
    BackTop,
    Sidebar,
    SidebarItem,
    Grid,
    GridItem,
    Swipe,
    SwipeItem,
    ActionBar,
    ActionBarIcon,
    ActionBarButton,
    Step,
    Steps,
    Popup,
    Lazyload,
    Tag,
    Barrage,
    Switch,
    Cell,
    CellGroup,
    Divider,
    Card,
    Tab, 
    Tabs,
    SubmitBar,
    Tabbar,
    TabbarItem,
    Empty,
    Collapse,
    CollapseItem,
    Search,
    Stepper,
    DatePicker,
    Picker,
    Field,
    Dialog,
    Row,
    Col,
    NoticeBar,
    DropdownMenu,
    DropdownItem,
} from 'vant'
import 'vant/lib/index.css'

const app = createApp(App)

app.use(router)

// vant
app.use(FloatingBubble)
app.use(NavBar)
app.use(Button)
app.use(Icon)
app.use(VanImage)
app.use(BackTop)
app.use(Sidebar)
app.use(SidebarItem)
app.use(Grid)
app.use(GridItem)
app.use(Swipe)
app.use(SwipeItem)
app.use(ActionBar)
app.use(ActionBarIcon)
app.use(ActionBarButton)
app.use(Step)
app.use(Steps)
app.use(Popup)
app.use(Lazyload)
app.use(Tag)
app.use(Barrage)
app.use(Switch)
app.use(Cell)
app.use(CellGroup)
app.use(Divider)
app.use(Card)
app.use(Tab)
app.use(Tabs)
app.use(SubmitBar)
app.use(Tabbar)
app.use(TabbarItem)
app.use(Empty)
app.use(Collapse)
app.use(CollapseItem)
app.use(Search)
app.use(Stepper)
app.use(DatePicker)
app.use(Picker)
app.use(Field)
app.use(Dialog)
app.use(Row)
app.use(Col)
app.use(NoticeBar)
app.use(DropdownMenu)
app.use(DropdownItem)


app.mount('#app')

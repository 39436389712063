import * as VueRouter from 'vue-router'

const routes = [
    {
        path: '/',
        redirect:'/home',
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '亿源牧业',
        },
        component: () => import('../pages/login/index.vue')
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            title: '亿源牧业',
        },
        component: () => import('../pages/home/index.vue')
    },
    {
        path: '/product',
        name: 'Product',
        meta: {
            title: '亿源牧业-产品',
        },
        component: () => import('../pages/product/index.vue')
    },
    {
        path: '/product_detail',
        name: 'ProductDetail',
        meta: {
            title: '亿源牧业-产品',
        },
        component: () => import('../pages/product/detail.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        meta: {
            title: '亿源牧业-客服',
        },
        component: () => import('../pages/home/contact.vue')
    },
    {
        path: '/mine',
        name: 'Mine',
        meta: {
            title: '亿源牧业-我的',
        },
        component: () => import('../pages/mine/index.vue')
    },
    {
        path: '/message',
        name: 'Message',
        meta: {
            title: '亿源牧业-消息',
        },
        component: () => import('../pages/message/index.vue')
    },
    {
        path: '/store',
        name: 'Store',
        meta: {
            title: '亿源牧业-网点',
        },
        component: () => import('../pages/store/index.vue')
    },
    {
        path: '/cart',
        name: 'Cart',
        meta: {
            title: '亿源牧业-购买',
        },
        component: () => import('../pages/cart/index.vue')
    },
    {
        path: '/renewal',
        name: 'Renewal',
        meta: {
            title: '亿源牧业-续订',
        },
        component: () => import('../pages/cart/renewal.vue')
    },
    {
        path: '/cycleinventory',
        name: 'CycleInventory',
        meta: {
            title: '亿源牧业-奶开-详情',
        },
        component: () => import('../pages/mine/cycleinventory.vue')
    },
    {
        path: '/order',
        name: 'Order',
        meta: {
            title: '亿源牧业-我的-订单',
        },
        component: () => import('../pages/mine/order.vue')
    },
    {
        path: '/order_page',
        name: 'OrderPage',
        meta: {
            title: '亿源牧业-我的-订单详情',
        },
        component: () => import('../pages/mine/orderpage.vue')
    },
    {
        path: '/wallet',
        name: 'Wallet',
        meta: {
            title: '亿源牧业-我的-钱包',
        },
        component: () => import('../pages/mine/wallet.vue')
    },
    {
        path: '/mine_coupon',
        name: 'MineCoupon',
        meta: {
            title: '亿源牧业-我的-优惠券',
        },
        component: () => import('../pages/mine/coupon.vue')
    },
    {
        path: '/recharge',
        name: 'Recharge',
        meta: {
            title: '亿源牧业-充值',
        },
        component: () => import('../pages/mine/recharge.vue')
    },
];
const router = VueRouter.createRouter({
    routes,
    history: VueRouter.createWebHistory(),
    // history: VueRouter.createWebHashHistory(),
});
export default router
<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
@import url('assets/css/common.css');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #333333;
}
</style>
